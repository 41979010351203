import React from "react"
import { Link } from "gatsby"
import Container from "../components/container"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <Container style={{ height: "50vh" }}>
      <h1 style={{ marginTop: 300 }}>
        <span>¡Gracias!</span>
      </h1>
      <p style={{ textAlign: "center" }}>We will get back to you soon!</p>
      <Link to="/" className="goback">
        Go Back
      </Link>
    </Container>
  </Layout>
)

export default SecondPage
